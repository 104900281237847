import React, { createContext, useReducer } from "react";

const initialState = {
  baseUrl: "",
  mediaBaseUrl: "",
  lang: localStorage.getItem("lang") || "en",
  home: {},
  header: [],
  footer: {},
  renovation: {},
  calculator: {},
  edelmetalle: {},
  energieLosungen: {},
  finanz: {},
  immobilien: {},
  immobilienPrivate: {},
  ITLosungen: {},
  partners: {},
  trueHand: {},
  uber: {},
  versicherung: {},
};

const defaultContextValue = {
  state: initialState,
  dispatch: () => initialState, // This is a placeholder function that does nothing
};

export const AppContext = createContext(defaultContextValue);

// Define the reducer function
const appReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "setHomeData":
      return {
        ...state,
        home: payload,
      };
    case "setHeaderData":
      return {
        ...state,
        header: payload,
      };
    case "setRenovationData":
      return {
        ...state,
        renovation: payload,
      };
    case "setCalculatorData":
      return {
        ...state,
        calculator: payload,
      };
    case "setEdelmetalleData":
      return {
        ...state,
        edelmetalle: payload,
      };
    case "setEnergieLosungenData":
      return {
        ...state,
        energieLosungen: payload,
      };
    case "setFinanzData":
      return {
        ...state,
        finanz: payload,
      };
    case "setImmobilienData":
      return {
        ...state,
        immobilien: payload,
      };
    case "setImmobilienPrivateData":
      return {
        ...state,
        immobilienPrivate: payload,
      };
    case "setITLosungenData":
      return {
        ...state,
        ITLosungen: payload,
      };
    case "setPartnersData":
      return {
        ...state,
        partners: payload,
      };
    case "setTrueHandData":
      return {
        ...state,
        trueHand: payload,
      };
    case "setUberData":
      return {
        ...state,
        uber: payload,
      };
    case "setVersicherungData":
      return {
        ...state,
        versicherung: payload,
      };
    case "setFooterData":
      return {
        ...state,
        footer: payload,
      };
    // case "setLang":
    //   localStorage.setItem("lang", payload);
    //   return {
    //     ...state,
    //     lang: payload,
    //   };
    //   return {
    //     // ...state,
    //     // calendarData: mapCalendarData(payload),
    //   };
    default:
      return state;
  }
};

// Create a context provider component
export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const contextValue = {
    state,
    dispatch,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
