import React from "react"; // Add this line
import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppProvider } from "./store/appContext";
import Homepage from "./pages/homepage/homepage";
import WebLoader from "./components/webLoader/WebLoader";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import ScrollToTop from "./components/ScrollToTop";
import Edelmetalle from "./pages/edelmetalle/edelmetalle";
import CashBack from "./pages/cashBack/cashBack";
import EnergyLosungen from "./pages/energie-losungen/energie-losungen";
import Finanz from "./pages/finanz/finanz";
import Immobilien from "./pages/immobilien/immobilien";
import ImmobilienPrivate from "./pages/immobilienPrivate/immobilienPrivate";
import ITLosungen from "./pages/IT-Losungen/IT-Losungen";
import Partners from "./pages/partners/partners";
import TrueHand from "./pages/trueHand/trueHand";
import Versicherung from "./pages/versicherung/versicherung";
import Calculator from "./pages/calculator/calculator";
import Uber from "./pages/uber/uber";
function App() {
  return (
    <AppProvider>
      <BrowserRouter>
        <ScrollToTop />
        <WebLoader />
        <Header />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/cash-back" element={<CashBack />} />
          <Route path="/edelmetalle" element={<Edelmetalle />} />
          <Route path="/edelmetalle/calculator" element={<Calculator />} />
          <Route path="/edelmetalle/uber" element={<Uber />} />
          <Route path="/energie-losungen" element={<EnergyLosungen />} />
          <Route path="/finanz" element={<Finanz />} />
          <Route path="/immobilien" element={<Immobilien />} />
          <Route path="/immobilien/private" element={<ImmobilienPrivate />} />
          {/* <Route path="/immobilien/renovation" element={<Renovation />} /> */}
          <Route path="/it-losungen" element={<ITLosungen />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/true-hand" element={<TrueHand />} />
          <Route path="/versicherung" element={<Versicherung />} />
          <Route path="*" element={<Homepage />} />
        </Routes>
        {/* <Contact /> */}
        <Footer />
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
